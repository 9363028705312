.Settings {
  padding: 2rem;
}

summary {
  text-align: left;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 1rem;
}

input[type='text'],
input[type='date'],
input[type='time'],
textarea,
select {
  padding: 2px 8px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ccc;
  margin: 0;
}

input[type='color'] {
  padding: 0;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0;
  height: 2rem;
  width: 2rem;
}
