.calendar_wrapper {
  padding: 0 2rem;
}

.calendar_wrapper h2 {
  margin-bottom: 2rem;
}

div.Calendar {
  grid-row: 2 / 7;
  grid-column: 2;
}

.Calendar table {
  border-collapse: collapse;
  cursor: default;
  margin: 0 auto;
  width: 92vw;
  max-width: 440px;
}

.Calendar table th {
  padding: 4px;
  border: 1px solid;
  color: white;
}

.Calendar table th:not(:last-child) {
  border-right: 1px solid white !important;
}

.Calendar table td {
  background-color: 1px solid #fafafa;
  color: #aaa;
  letter-spacing: -1px;
  font-size: 1.2rem;
  text-align: right;
  vertical-align: top;
  padding: 4px 12px;
  height: 3.6rem;
  font-family: 'Song Myung', serif;
}

.Calendar table td:not(:last-child) {
  border-right: 1px solid #eee;
}

.Calendar table tr:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.Calendar table td:hover {
  background-color: #efefef;
}

.Calendar table td:first-child,
.Calendar table td:last-child {
  color: #333;
}

.Calendar table td.theday {
  background-color: #f4dbbb;
  font-weight: bold;
  color: white;
}

.Calendar td:empty {
  background-color: #fafafa;
}

.calendar_wrapper .header_left {
  grid-row: 4;
  grid-column: 1;
}
.calendar_wrapper .header_right {
  grid-row: 4;
  grid-column: 3;
}
.calendar_wrapper h3 {
  grid-column: 2;
  grid-row: 1;
}

@media (max-width: 440px) {
  .calendar_wrapper {
    padding: 0;
  }

  .Calendar table th {
    padding: 2px;
  }

  .Calendar table td {
    height: calc(92vw / 7);
    padding: 2px 10px;
  }
}
