.Gallery {
}

.Gallery div {
  cursor: pointer;
  background-position: center;
  background-size: cover;
}

.current_image div {
  min-height: 600px;
  max-height: 600px;
}

.current_thumbnail {
  outline: 3px solid;
}

.thumbnail_section {
  display: grid;
  grid-template-columns: 3rem 1fr 3rem;
  margin: 8px;
  align-items: center;
}

.thumbnails {
  display: grid;
  gap: 4px;
}

.thumbnails.five {
  grid-template-columns: repeat(5, 1fr);
}

.thumbnails.three {
  grid-template-columns: repeat(3, 1fr);
}

.thumbnails > div {
  min-height: 6rem;
  transition: all 0.3s ease;
}

.thumbnails > div:hover {
  transform: scale(1.1);
  box-shadow: 0 0 6px 0 #00000040;
}

.picture_wrapper {
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
}

.Picture {
  position: relative;
}

.Picture img {
  margin: 0 auto;
  max-height: 90vh;
  position: relative;
}

.Picture p {
  margin-block-start: 1rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(to top, #00000090, transparent);
  color: white;
  padding: 1rem 2rem;
  width: 100%;
}

.button:hover {
  transform: scale(1.1);
}

@media (max-width: 600px) {
  .current_image div {
    min-height: 100vw;
  }

  .thumbnails > div {
    min-height: 15vw;
  }
}
