.Title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(254, 254, 254);
  box-shadow: inset 0px 16px 40px -40px #00000020;
  padding: 0 2rem;
  font-family: 'Gowun Batang', serif;
}

.top {
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  margin: 1rem auto 0 auto;
  letter-spacing: 6px;
}

.top img {
  height: 1.4rem;
  transform: translate(-15%, 0%);
}

.date,
.location {
  letter-spacing: 4px;
}

.couple {
  font-size: 2.2rem;
  letter-spacing: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.couple p {
  letter-spacing: 0;
  font-family: 'WindSong';
  transform: translate(-12.5%, 0);
  padding: 0 1rem;
}

.couple img {
  height: 1.8rem;
  display: inline;
}

.address {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
  line-height: 2.4;
}

.address label {
  letter-spacing: 2px;
}

.image {
  margin-top: 2rem;
  width: 100%;
  aspect-ratio: 4 / 3;
  background-size: cover;
  background-position-x: center;
  box-shadow: 0px 0px 24px -12px #00000020;
  background-repeat: no-repeat;
}

.scrolldown {
  padding-bottom: 4vh;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.scrolldown div {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 16px 12px -12px #00000050;
  width: 10vw;
  height: 10vw;
  max-width: 3rem;
  max-height: 3rem;
  border-radius: 100%;
  cursor: pointer;
}

.scrolldown div:hover {
  background-color: white;
}

@media (max-width: 600px) {
  .couple {
    font-size: 6vw;
    letter-spacing: 4px;
  }

  .address,
  .top {
    font-size: 3.6vw;
  }
  .top img {
    height: 4vw;
  }
  .top {
    height: 10vh;
  }

  .address {
    margin-top: 2vw;
  }

  .Title {
    padding: 0 4vw;
  }

  .image {
    margin-top: 2vw;
  }
}
