.calendar_wrapper {
  padding: 2rem 2rem 0.5rem 2rem;
}

.calendar_wrapper > p {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.Calendar {
  padding: 1rem;
  background: white;
  grid-row: 2 / 7;
  grid-column: 2;
  border-radius: 5px;
}

.Calendar p {
  font-family: 'Song Myung', serif;
  font-size: 1.3rem;
}

.time {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem 1.2rem;
}

.time > div:first-child {
  border-right: 2px solid white;
}

.time > div {
  display: grid;
  gap: 4px;
}

.Calendar table {
  border-spacing: 1rem;
  cursor: default;
  margin: 0 auto;
}

.Calendar table th {
  padding: 0 4px;
  border-radius: 100%;
}

.Calendar table td {
  width: 2.2rem;
  border-radius: 100%;
}

.Calendar table td > div {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -1px;
  font-size: 1rem;
  border-radius: 100%;
  aspect-ratio: 1 / 1;
}

.Calendar table td:hover {
  background-color: #efefef;
}

.Calendar td:empty {
  background-color: #fafafa;
}

.calendar_wrapper .header_left {
  grid-row: 4;
  grid-column: 1;
}
.calendar_wrapper .header_right {
  grid-row: 4;
  grid-column: 3;
}
.calendar_wrapper h3 {
  grid-column: 2;
  grid-row: 1;
}

.notice {
  margin-top: 1rem;
  color: #888;
  font-size: 1rem !important;
}

@media (max-width: 440px) {
  .Calendar {
    padding: 1rem 0;
  }

  .calendar_wrapper {
    padding: 2rem 1rem 1rem 1rem;
  }

  .time {
    padding: 1rem 0;
  }

  .notice {
    font-size: 0.9rem !important;
  }

  .Calendar table {
    border-spacing: 2vw;
  }
}
