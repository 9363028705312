.calendar_wrapper {
  padding: 1rem;
}

.calendar_wrapper img {
  margin: 8px auto;
}

div.Calendar {
  grid-row: 2 / 7;
  grid-column: 2;
}

.Calendar table {
  border-spacing: 1rem;
  cursor: default;
  margin: 0 auto;
}

.Calendar table th {
  padding: 0 0.5rem;
  border-radius: 100%;
}

.Calendar table td {
  letter-spacing: -1px;
  font-size: 1.2rem;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
  width: 2.4rem;
  height: 2.4rem;
  font-family: 'Song Myung', serif;
}

.Calendar table td:hover {
  background-color: #efefef;
}

.Calendar table td:first-child,
.Calendar table td:last-child {
  color: #333 !important;
}

.Calendar td:empty {
  background-color: #fafafa;
}

.calendar_wrapper .header_left {
  grid-row: 4;
  grid-column: 1;
}
.calendar_wrapper .header_right {
  grid-row: 4;
  grid-column: 3;
}
.calendar_wrapper h3 {
  grid-column: 2;
  grid-row: 1;
}
