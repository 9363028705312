.Dday {
  font-family: 'MonteCarlo', cursive;
  font-size: 4rem;
  border-bottom: 1px solid white;
  width: 100%;
  margin-top: 3rem;
  position: relative;
}

.background {
  width: 100%;
  height: 150px;
  background-size: 150%;
  background-position-x: center;
  background-position-y: top;
  filter: opacity(0.5) blur(1px);
}

.Dday img {
  height: 2.5rem;
  margin: 0 auto;
  margin-top: 1rem;
}

.contents {
  display: grid;
  align-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 12px -4px #00000040;
  backdrop-filter: blur(3px);
  width: 100%;
  text-shadow: 2px 4px 1px #00000030;
  height: 100%;
}
