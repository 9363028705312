/*USER CUSTOM FONTS*/
@font-face {
  font-family: 'SourceHanSerifCN';
  src: url('../fonts/SourceHanSerifCN-Regular-1.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SourceHanSerifCN';
  src: url('../fonts/SourceHanSerifCN-Bold-2.woff') format('woff');
  font-weight: 600;
}
@font-face {
  font-family: 'SourceHanSerifCN';
  src: url('../fonts/SourceHanSerifCN-Bold-2.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'Recipekorea';
  src: url('../fonts/Recipekorea.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Paperlogy';
  src: url('../fonts/Paperlogy-5Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Paperlogy';
  src: url('../fonts/Paperlogy-6SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: bold;
}
@font-face {
  font-family: 'Paperlogy';
  src: url('../fonts/Paperlogy-8ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: extrabold;
}
@font-face {
  font-family: 'Paperlogy';
  src: url('../fonts/Paperlogy-9Black.woff') format('woff');
  font-weight: 900;
  font-style: black;
}
@font-face {
  font-family: 'VITROCORE';
  src: url('../fonts/VITROCORE.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

/* Google web font */
/* San-serif */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Serif */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@200;300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@400;700&family=Gowun+Dodum&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Song+Myung&display=swap');

/* Cursive */
@import url('https://fonts.googleapis.com/css2?family=MonteCarlo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=WindSong:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hurricane&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arizonia&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Corinthia:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oleo+Script+Swash+Caps:wght@400;700&display=swap');

/* Handwriting */
@import url('https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Meow+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Whisper&display=swap');

/* Cute */
@import url('https://fonts.googleapis.com/css2?family=Single+Day&display=swap');
