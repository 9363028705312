.Footer {
  color: white;
  padding: 1rem;
  box-shadow: 0 -6px 24px -6px #00000010;
  margin-top: 8rem;
  border-top: 1px solid;
}

.Footer p {
  font-size: 0.7rem;
  letter-spacing: 1px;
  margin-block-start: 0;
}

.Footer p > span {
  font-weight: bold;
  cursor: pointer;
}

.Footer p > span:hover {
  text-decoration: underline;
}

.Footer div {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 1rem 0 2rem 0;
}

.Footer button {
  width: 3rem;
  border-radius: 100%;
  padding: 6px;
  background-color: #00000010;
}

.Footer button:first-child img {
  width: 100%;
  border-radius: 100%;
}

.Footer button:nth-child(2) img {
  width: 70%;
  margin: 0 auto;
}

.Footer button:last-child img {
  width: 100%;
}
