.Invitation {
  padding: 2rem 6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Invitation.withBackground {
  padding: 8rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.Invitation h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Invitation h1::before,
.Invitation h1::after {
  content: '';
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 1rem 0;
  background-image: url('../../../../assets/deco/diagonal.svg');
  background-size: cover;
}

.message {
  white-space: pre-line;
  line-height: 2.6;
  text-align: left;
}

.profile {
  border-top: 1px solid;
  margin-top: 2rem;
  padding: 2rem 0 1rem 0;
  display: grid;
  gap: 1rem;
}

.parents {
  display: inline-flex;
  margin-right: 6px;
  gap: 6px;
}

.parents span {
  font-size: 1.4rem;
}

.chrys::before {
  content: '';
  background-image: url('../../../../assets/icon/chry-watercolor.png');
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin-right: 3px;
}

.Invitation .profile > div > span:nth-child(2) {
  font-size: 1.1rem;
}

.Invitation .profile > div > label {
  margin-left: 12px;
  font-size: 1.6rem;
  letter-spacing: 8px;
  word-break: keep-all;
  display: inline-block;
}

.Invitation .small {
  font-size: 0.9rem;
}

@media (max-width: 600px) {
  .Invitation {
    padding: 8vw 12vw;
  }

  .Invitation.withBackground {
    padding: 16vw;
  }

  .Invitation .small {
    font-size: 0.85rem;
  }
}

@media (max-width: 440px) {
  .Invitation.withBackground {
    padding: 6rem 3rem;
  }

  .Invitation .message {
    font-size: 3.8vw;
  }

  .Invitation div.parents span {
    font-size: 5vw;
  }

  .Invitation .small {
    font-size: 3.4vw;
  }

  .Invitation .chrys::before {
    width: 3.6vw;
    height: 3.6vw;
  }

  .Invitation .profile > div > span:nth-child(2) {
    font-size: 4vw;
  }

  .Invitation .profile > div > label {
    font-size: 6vw;
  }
}
