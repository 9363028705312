.Dday {
  font-family: 'Single Day', serif;
  font-size: 4rem;
  width: 80%;
  margin: 4rem auto 0 auto;
  position: relative;
  border-radius: 10rem;
}

.background {
  width: 100%;
  height: 160px;
  background-position-x: center;
  border-radius: 10rem;
}

.Dday img {
  height: 2.5rem;
}

.contents {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  filter: invert(1);
}

.contents.noBackground {
  filter: invert(0);
}
