.Header {
  width: 100%;
  max-width: 600px;
  position: fixed;
  transition: all 0.8s ease-in-out;
  backdrop-filter: blur(10px);
  font-size: 1.15rem;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.4);
}

.Header > div:first-child {
  height: 6px;
  top: 0;
}

.Header > div:nth-child(2) {
  top: 6px;
  display: grid;
  grid-template-columns: 1rem 1fr 1rem;
  height: 4rem;
  text-align: center;
  align-items: center;
  padding: 0 1rem;
  box-shadow: 0 6px 6px -6px #00000010;
}

.menu {
  text-align: right;
  cursor: pointer;
}

.Menus {
  padding: 1.5rem 0;
  box-shadow: 0 6px 6px -6px #00000010;
  font-size: 1.15rem;
}

.Menus > div {
  padding: 8px;
  cursor: pointer;
}

.Menus > div > span {
  cursor: pointer;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}

.Menus > div:hover {
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.4);
  transition: all 1s ease-in-out;
}

@media (max-width: 600px) {
  .Header {
    font-size: 1rem;
  }

  .Header > div:nth-child(2) {
    height: 12vw;
  }
}

@media (max-width: 440px) {
  .Header {
    font-size: 4vw;
  }
}
