@import './font.css';

/* 1. Global Setting */
:root {
  --background: #f5f9fc;
  --highlight-tp: rgba(79, 140, 174, 0.2);
  --highlight: rgba(79, 140, 174, 0.5);
  --highlight-deep: rgba(79, 140, 174, 1);
  --point: #f4dbbb;
}

body {
  height: 100dvh;
  font-family: 'Gowun Batang', serif;
  word-break: keep-all;
  overflow-x: hidden !important;
}

span,
p,
div {
  cursor: default;
}

b {
  word-break: keep-all;
}

label {
  font-weight: bold;
}

h1 {
  margin-bottom: 2rem;
}

h2 {
  margin-bottom: 1rem;
}

button {
  outline: none;
  border-radius: 20px;
  border: none;
  cursor: pointer !important;
  font-weight: bold;
  transition: all 0.5s ease-in-out;
  padding: 4px 1rem;
  word-break: keep-all;
}

button:hover {
  background-color: #ccc;
}

.smalltext {
  font-size: small;
}

/* 2. Modal */
.Modal {
  width: 100%;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  transition: all 0.5s ease-in-out;
}

.Modal.menu {
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.4);
  top: calc(4rem + 6px);
  backdrop-filter: blur(10px);
}

@media (max-width: 600px) {
  .Modal.menu {
    top: calc(12vw + 6px);
  }
}

.Modal.gallery {
  background-color: #0005;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_wrapper {
  box-shadow: 0 6px 6px -6px #00000020;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.5s ease-in-out;
}

.Modal.gallery .modal_wrapper {
  max-width: 90%;
  max-height: 90vh;
  border-radius: 2px;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 12px 12px -12px #00000030;
}
@media (max-width: 440px) {
  .Modal.gallery .modal_wrapper {
    padding: 2vw;
  }
}

/* 3. Animation */
.hide {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  transform: translateY(-4rem);
}

.show {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  transform: translateY(0);
}

/* Slick */
.slick-dots li {
  width: 16px !important;
  height: 16px !important;
}

.slick-dots li button:before {
  font-size: 8px !important;
  color: #aaa !important;
}

.slick-dots li.slick-active button:before {
  color: rgb(42, 107, 135) !important;
}

/* Others */
/* Map Tooltip */
.kakaoInfoWindow {
  text-align: center;
  border: 2px solid;
  position: relative;
  display: inline-block;
  border-radius: 50px;
  background: #fffd;
  padding: 2px 16px;
  box-shadow: 0px 4px 8px -6px #0003;
}

.kakaoInfoWindow > div {
  content: '';
  position: absolute;
  bottom: -10px; /* 삼각형 높이에 따라 조정 */
  left: 50%;
  transform: translate(-50%, 50%);
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

/* Music Player */
.rhap_container {
  padding: 2px 2rem;
  line-height: 0;
  max-width: 320px;
  margin: 0 auto;
  background: none;
  box-shadow: none !important;
}

.rhap_time {
  display: none; /* 시간 표시 제거 */
}

.rhap_additional-controls {
  display: none !important;
}

.rhap_progress-section {
  position: absolute;
  width: 166px;
  transform: translate(50px, 14px);
  pointer-events: none;
}

.rhap_play-pause-button {
  font-size: 2rem;
  width: 2rem;
  height: 2rem;
  color: inherit;
}

.rhap_progress-indicator {
  width: 12px !important;
  height: 12px !important;
  margin-left: 0 !important;
  top: -4px !important;
  color: inherit !important;
}

.rhap_progress-container {
  margin: 0 !important;
}

.rhap_download-progress {
  width: 100% !important;
}

.rhap_volume-bar-area {
  display: none !important;
}

.rhap_volume-button {
  font-size: 22px !important;
  height: 22px !important;
  margin: 0 !important;
}

.rhap_volume-container {
  flex: unset !important;
}

@media (max-width: 400px) {
  .rhap_play-pause-button {
    font-size: 8vw !important;
    width: 8vw !important;
    height: 8vw !important;
    color: inherit !important;
  }

  .rhap_volume-button {
    font-size: 20px !important;
    height: 20px !important;
  }

  .rhap_container {
    width: 80vw !important;
  }

  .rhap_progress-section {
    width: calc(79vw - 150px);
    transform: translate(50px, calc(4vw - 2px));
  }
}
