.Designer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  padding: 0 2rem;
}

.Preview {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  margin: 0 auto;
  box-shadow: 0 0 8px 0px #00000010;
}
.contents {
  padding: 0;
}

.Main section {
  margin-bottom: 4rem;
}

.ornament {
  margin: 6rem auto 2rem auto;
  height: 5rem;
}

.line {
  height: 0px;
  margin: 7rem auto 1rem auto;
}

.line + h1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line + h1::before,
.line + h1::after {
  content: '';
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 1rem 0;
  background-image: url('../../assets/deco/diagonal.svg');
  background-size: cover;
}

h2.subTitle {
  align-items: center;
  font-weight: bolder;
  letter-spacing: 2px;
  font-size: 1.4rem;
  margin: 2rem;
}

.subline + h2.subTitle {
  display: flex;
  flex-direction: column;
}

.subline + h2.subTitle::before {
  content: '';
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 2rem;
  background-image: url('../../assets/deco/diagonal.svg');
  background-size: cover;
}
