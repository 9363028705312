.Contact {
  display: grid;
  padding: 0 4rem;
  gap: 1rem;
}

.Contact button {
  display: flex;
  align-items: center;
  gap: 4px;
}

.Contact button > span {
  cursor: pointer;
}

.card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 1rem;
  align-items: center;
  border-bottom: 2px solid #ddd;
}

.card > div {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.icons img {
  width: 2rem;
  filter: invert(1);
}

.icons > button {
  padding: 8px;
  width: 3rem;
}

.column label {
  font-size: 1.2rem;
  font-weight: 400;
}

.parents_card table {
  width: 100%;
}

.parents_card table td:first-child {
  font-weight: bold;
}

.parents_card img {
  width: 1rem;
}

.parents_card td {
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.parents_card td:nth-child(2) {
  text-align: left;
}

.parents_card td:last-child {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

@media (max-width: 440px) {
  .Contact {
    padding: 0 2rem;
  }

  .Contact button > span {
    display: none;
  }
}
